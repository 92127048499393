<template>
  <tr :style="{ background: props.item.visibility_id == 2 ? '#f1f1f1': 'white' }">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <v-img v-if="props.item.file" :src="props.item.file.url" max-height="48px" max-width="48px"></v-img>
      <v-icon v-else color="primary" large>image</v-icon>
    </td>
    <td class="justify-left">
      <label v-if="props.item.site" style="color: gray;">
        Site: <strong>{{ props.item.site.name }}</strong>
      </label>
      <label
        v-for="(category, categoryIndex) in props.item.categories"
        :key="categoryIndex"
        :style="{ background: category.color }"
        style="margin: 0 5px; padding: 2px 5px; border-radius: 10px; color: white;"
      >
        {{ category.name }}
      </label>
      <br>
      <strong>
        {{ props.item.title }}
      </strong>
    </td>
    <td class="justify-center">
      <strong v-if="props.item.status_id == 1" style="display: flex; align-content: center; flex-direction: column;">
        <v-icon color="orange">edit_square</v-icon>
        <span>Rascunho</span>
      </strong>
      <strong v-if="props.item.status_id == 2" style="display: flex; align-content: center; flex-direction: column;">
        <v-icon color="green">done_outline</v-icon>
        <span>Publicado</span>
      </strong>
      <strong v-if="props.item.status_id == 3" style="display: flex; align-content: center; flex-direction: column;">
        <v-icon color="red">close</v-icon>
        <span>Cancelado</span>
      </strong>
    </td>
    <td class="justify-center">
      <strong v-if="props.item.visibility_id == 1" style="display: flex; align-content: center; flex-direction: column;">
        <v-icon color="primary">visibility</v-icon>
        <span>Público</span>
      </strong>
      <strong v-if="props.item.visibility_id == 2" style="display: flex; align-content: center; flex-direction: column;">
        <v-icon color="black">visibility_off</v-icon>
        <span>Privado</span>
      </strong>
      <strong v-if="props.item.visibility_id == 3" style="display: flex; align-content: center; flex-direction: column;">
        <v-icon color="black">visibility_lock</v-icon>
        <span>Protegido</span>
      </strong>
    </td> 
    <td class="justify-center">
      {{props.item.date_time_formated}}
    </td>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="listPostCategories()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>category</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Categorias</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="listPostImages()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>image</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Imagens</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Posts',
    methods: {
      listPostImages: function () {
        this.$WiListDialog({
          wiConfig: 'post-file',
          filter: {
            post_id: this.props.item.id
          },
          wiEditData: {
            post_id: this.props.item.id
          }
        })
      },
      listPostCategories: function () {
        this.$WiListDialog({
          wiConfig: 'post-category',
          filter: {
            post_id: this.props.item.id
          },
          wiEditData: {
            post_id: this.props.item.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>